import React,{Fragment} from 'react'
import {Link} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../../components/seo/seo";
import AOS from "aos";
import logo from '../../../assets/img/virsat-logo-light.svg'

const ThankYou = () => {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Seo
        title='Thank You | VIRSAT - Virtual Reality Safety Training'
        description='Thank You'
      />
      <div className="main-container thank-you-lp">
        <div className="thank-you-lp-bg">
          <StaticImage
            src='../../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT Thank You Backgorund'
            placeholder='blurred'
            objectFit
          />
        </div>
        <div className="row">
          <div className="column show-overflow">
            
            <h1 className="main-title secondary-color center">Thank you!</h1>
            <p className="light description center">It has been a pleasure having you in this webinar!</p>
            <p className="light description center">
            For more details on Virtual Reality Learning Experience, send an email to <a className="light" href="mailto:info@virsat.com">info@virsat.com</a>.
            </p>
            <p className="light description center">You may also visit us at <a href="www.virsat.com">www.virsat.com</a> to learn more.</p>
            <p className="light description center">Thank you and have a wonderful day!</p>

            <Link to="/" className="thank-you-logo">
              <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ThankYou
